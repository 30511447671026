import React, { useState, useEffect } from "react";
import { getAllTeamMembers } from "../../services/teamServices";

const NosEquipes = () => {
  const [team, setTeam] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const data = await getAllTeamMembers();
      setTeam(data);
    };

    fetchTeamMembers();
  }, []);

  // Fonction pour ouvrir la modale avec l'image sélectionnée
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section
      className="py-12 bg-lightBackground dark:bg-darkBackground text-black dark:text-white"
      id="nosEquipes"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center -mx-4 overflow-hidden">
          <div className="font-satoshi px-8 w-full lg:w-2/3 overflow-hidden my-4 text-center">
            <h2 className="text-3xl font-extrabold mb-4 text-primary mx-auto font-satoshi text-center">
              NOS ÉQUIPES
            </h2>
            <div className="flex flex-wrap justify-center">
              {team.map((member) => (
                <div
                  key={member.id}
                  className="w-1/2 sm:w-1/2 md:w-1/3 p-4 text-center"
                >
                  <img
                    src={member.photoURL}
                    alt={`${member.nom} ${member.prenom}`}
                    className="rounded-lg mx-auto mb-2 w-48 h-48 object-cover font-satoshi"
                    onClick={() =>
                      openModal(member.photoURL || "/img/no-image.jpg")
                    } // Ouvre la modale avec l'image par défaut si aucune image
                  />
                  <h3 className="text-lg">
                    <span className="font-semibold font-satoshi uppercase">
                      {member.nom}{" "}
                    </span>
                    <span className="font-satoshi">{member.prenom}</span>
                  </h3>
                  <p className="italic text-gray-400 font-bold text-sm font-satoshi">
                    {member.fonction}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Modale pour afficher l'image en grand */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative">
              <img
                src={selectedImage}
                alt="Agrandissement"
                className="max-w-full max-h-[80vh] rounded-lg"
              />
              <button
                className="absolute top-2 right-2 text-white text-2xl"
                onClick={closeModal}
                aria-label="Fermer"
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default NosEquipes;
