import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkerAlt, FaInfoCircle } from "react-icons/fa";
import { getUpcomingEvents } from "./../../services/eventServices";
import Button from "./../../components/Button/Button";
import { useUser } from "./../Home/UserContext";

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const upcomingEvents = await getUpcomingEvents(); // Assurez-vous que cette fonction est bien implémentée dans vos services
        setEvents(upcomingEvents.slice(0, 3)); // Gardez seulement les trois prochains événements
      } catch (error) {
        console.error("Failed to fetch events", error);
      }
    };

    fetchEvents();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleMoreInfo = () => {
    if (!user) {
      // Si aucun utilisateur n'est connecté, affichez un message pour l'inviter à se connecter
      alert("Veuillez vous connecter pour accéder à cette page.");
      navigate("/login"); // Redirige vers la page de connexion
    } else {
      navigate("/calendrier"); // Redirige vers la page du calendrier si l'utilisateur est connecté
    }
  };

  // Fonction pour ouvrir la modale avec l'image sélectionnée
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mb-2 font-satoshi uppercase">
        Prochains Événements & Compétitions
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        {events.map((event) => (
          <div
            key={event.id}
            className="flex flex-col bg-slate-100 dark:bg-slate-500 shadow-md rounded-lg overflow-hidden"
          >
            <div className="flex-grow">
              <img
                src={event.photo_url}
                alt={event.titre}
                className="w-full h-64 object-cover"
                onClick={() =>
                  openModal(event.photo_url || "/img/no-image.jpg")
                } // Ouvre la modale avec l'image par défaut si aucune image
              />
              <div className="p-4">
                <h3 className="text-xl font-bold">{event.titre}</h3>
                <p className="text-sm">{event.description}</p>
                <div className="mt-2 text-sm">
                  <div className="flex items-center">
                    <FaCalendarAlt className="mr-2" />
                    {formatDate(event.start)} -{" "}
                    {event.end ? formatDate(event.end) : ""}
                  </div>
                  <div className="flex items-center mt-1">
                    <FaMapMarkerAlt className="mr-2" />
                    {event.lieu_event || "Lieu à préciser"}
                  </div>
                </div>
              </div>
            </div>
            {/* Si l'utilisateur est connecté, affichez le bouton "Plus d'infos" */}
            {user && (
              <Button
                text="Plus d'infos"
                onClick={handleMoreInfo}
                icon={FaInfoCircle}
                className="mt-4 gap-2 mx-4 mb-4"
              />
            )}
          </div>
        ))}
      </div>
      {/* Modale pour afficher l'image en grand */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Agrandissement"
              className="max-w-full max-h-[80vh] rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={closeModal}
              aria-label="Fermer"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
