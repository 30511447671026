import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/techniques`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les techniques
export const getAllTechniques = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allTechniques`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des techniques:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les techniques judo uniquement sans les familles : Kata, Vocabulaire, Les Bases, Ukemi
export const getTechniquesJudo = async () => {
  try {
    const response = await axios.get(`${apiUrl}/techniques-judo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des techniques judo:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher les techniques judo uniquement sans les familles : Kata, Vocabulaire, Les Bases, Ukemi et Self Défense
export const getJudoTechniquesWithoutSelfDefense = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/techniques-judo-without-self-defense`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors du chargement des techniques judo sans self-defense:",
      error
    );
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour afficher uniquement les techniques qui font parti de l'UV2
export const getTechniquesUV2 = async () => {
  try {
    const response = await axios.get(`${apiUrl}/techniques-uv2`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des techniques UV2:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};
