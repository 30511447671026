// /client/src/components/DashboardCompta.js
import React, { useState, useEffect } from "react";
import CardCompta from "./CardCompta";
import CardComptaNumber from "./CardComptaNumber";
import StatutReglementsDiagramme from "./StatutReglementsDiagramme";
import TableauAchatsGroupes from "./TableauAchatsGroupes";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { getAllReglementInscriptionBySaison } from "../../../services/reglementinscriptionServices";
import { getSettings } from "../../../services/settingsServices";
import useScrollToTop from "../../../hooks/useScollToTop";
import TypeReglementsDiagramme from "./TypeReglementsDiagramme";

const DashboardCompta = () => {
  useScrollToTop();
  const [totalLicences, setTotalLicences] = useState(0);
  const [totalCotisations, setTotalCotisations] = useState(0);
  const [totalReductions, setTotalReductions] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalPassSport, setTotalPassSport] = useState(0);
  const [nombrePassSport, setNombrePassSport] = useState(0);
  const [statutReglements, setStatutReglements] = useState([]);
  const [typeReglements, setTypeReglements] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      fetchReglements(selectedSeason);
    }
  }, [selectedSeason]);

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setSelectedSeason(activeSeason);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const fetchReglements = async (season) => {
    try {
      const reglements = await getAllReglementInscriptionBySaison(season);
      if (!reglements || !Array.isArray(reglements)) {
        console.error(
          "Erreur: Les données reçues ne sont pas un tableau ou sont indéfinies",
          reglements
        );
        return;
      }

      let totalLicences = 0;
      let totalCotisations = 0;
      let totalReductions = 0;
      let nombrePassSport = 0;
      let totalPassSports = 0;
      let statutCounts = {};
      let typeCounts = {};

      reglements.forEach((reglement) => {
        totalLicences += parseFloat(reglement.montant_lic_assurance || 0);
        totalCotisations += parseFloat(reglement.montant_cotisation || 0);
        totalReductions += parseFloat(
          (reglement.reduction_foyer || 0) +
            (reglement.reduction_saison || 0) +
            (reglement.pass_sport || 0)
        );
        nombrePassSport += parseFloat(reglement.pass_sport || 0) / 50;
        totalPassSports += parseFloat(reglement.pass_sport || 0);

        // Comptage des statuts
        const statut = reglement.statut_reglement;
        if (statut) {
          if (!statutCounts[statut]) {
            statutCounts[statut] = 0;
          }
          statutCounts[statut]++;
        }

        // Comptage des types de règlements
        const type = reglement.type_reglement;
        if (type) {
          if (!typeCounts[type]) {
            typeCounts[type] = 0;
          }
          typeCounts[type]++;
        }
      });

      setTotalLicences(totalLicences);
      setTotalCotisations(totalCotisations);
      setTotalReductions(totalReductions);
      setTotalPassSport(totalPassSports);
      setTotalGeneral(totalLicences + totalCotisations + totalReductions);
      setNombrePassSport(Math.abs(Math.round(nombrePassSport)));

      // Calcul des pourcentages des statuts
      const totalReglements = reglements.length;
      const statutReglementsPercent = Object.entries(statutCounts).map(
        ([key, value]) => ({
          statut: key,
          percent: ((value / totalReglements) * 100).toFixed(1), // Calcul du pourcentage
        })
      );

      // Calcul des pourcentages des types de règlements
      const typeReglementsPercent = Object.entries(typeCounts).map(
        ([key, value]) => ({
          type: key,
          percent: ((value / totalReglements) * 100).toFixed(1), // Calcul du pourcentage
        })
      );

      setStatutReglements(statutReglementsPercent);
      setTypeReglements(typeReglementsPercent);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des règlements d'inscription",
        error
      );
    }
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Statistiques Comptabilité
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mb-4 max-w-[1200px] w-full mx-auto">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Sélectionner la saison
        </label>
        <select
          className="input input-bordered w-full dark:text-black"
          value={selectedSeason}
          onChange={(e) => setSelectedSeason(e.target.value)}
        >
          {seasons.map((season) => (
            <option key={season} value={season}>
              {season}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col items-center w-full px-4 mt-4 font-montserrat max-w-[1280px] mx-auto">
        <h2 className="text-2xl uppercase font-bold mb-5 dark:text-white">
          Règlements d'Inscriptions
        </h2>

        <div className="flex flex-wrap px-2 mb-8 gap-5 mx-auto">
          <CardCompta
            title="Total Licences"
            value={totalLicences}
            color="blue"
          />
          <CardCompta
            title="Total Cotisations - Réductions"
            value={totalCotisations}
            color="yellow"
          />
          <CardCompta
            title="Total Réductions"
            value={totalReductions}
            color="red"
          />
          <CardCompta
            title="Total Règlements"
            value={totalGeneral}
            color="green"
          />
          <CardCompta
            title="Total Pass Sport"
            value={totalPassSport}
            color="purple"
          />
          <CardComptaNumber
            title="Nombre de Pass'Sport"
            value={nombrePassSport}
            color="black"
          />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Répartition des Statuts de Règlements
          </h2>
          <StatutReglementsDiagramme data={statutReglements} />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Répartition des Types de Règlements
          </h2>
          <TypeReglementsDiagramme data={typeReglements} />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-left dark:text-white">
            Achats Articles Boutique
          </h2>
          <TableauAchatsGroupes selectedSeason={selectedSeason} />
        </div>
      </div>
    </div>
  );
};

export default DashboardCompta;
