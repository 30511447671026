import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaUsers,
  FaCalendarAlt,
  FaEnvelope,
  FaMoneyBillWave,
  FaUserCheck,
  FaChartBar,
  FaAddressCard,
  FaCartPlus,
  FaRegCreditCard,
  FaUserCircle,
  FaFileSignature,
  FaRegNewspaper,
  FaImage,
  FaComment,
  FaHourglassHalf,
  FaCalculator,
  FaShoppingBag,
} from "react-icons/fa";
import { IoMdContacts, IoMdSettings } from "react-icons/io";
import {
  GiBlackBelt,
  GiKimono,
  GiPodiumWinner,
  GiPodium,
  GiGong,
} from "react-icons/gi";
import { MdDashboard, MdTimer, MdOutlineInventory } from "react-icons/md";
import { MdScoreboard } from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { LuListTodo } from "react-icons/lu";
import { BsClipboardCheckFill } from "react-icons/bs";
import { RiQuestionnaireFill } from "react-icons/ri";
// Association mise à jour des titres de liens et des icônes
const linkIcon = {
  "Tableau de Bord": <MdDashboard />,
  "Dossier d'Inscription": <FaAddressCard />,
  "Fiche Adhérent": <FaUserCircle />,
  Calendrier: <FaCalendarAlt />,
  Messages: <FaEnvelope />,
  Chat: <FaComment />,
  Boutique: <FaCartPlus />,
  "Commandes & Règlements": <FaRegCreditCard />,
  "Techniques Judo": <GiKimono />,
  "Techniques Quizz": <GiKimono />,
  Actualités: <FaRegNewspaper />,
  Lexique: <PiTranslateFill />,
  "Arbitrage Judo": <GiGong />,
  "Judo Score": <MdScoreboard />,
  "Galerie Photos": <FaImage />,
  "Listing Adhérents": <IoMdContacts />,
  "Passage Ceinture": <GiBlackBelt />,
  "Grade CN": <GiBlackBelt />,
  "Suivi Compétitions": <GiPodiumWinner />,
  Palmarès: <GiPodium />,
  ToDoList: <LuListTodo />,
  Événements: <FaCalendarAlt />,
  "Appel Présence": <FaUserCheck />,
  "Tableau Appel": <BsClipboardCheckFill />,
  "Dashboard Adhérents": <FaChartBar />,
  "Dashboard Comptabilité": <FaChartBar />,
  "Détails Adhérent": <FaUserCircle />,
  "Listing Utilisateurs": <FaUsers />,
  "Listing Adhérents Admin": <FaUsers />,
  "Règlements Inscriptions": <FaMoneyBillWave />,
  "Dossiers d'Inscription": <FaFileSignature />,
  "Achats Boutique": <FaCartPlus />,
  "Articles Admin": <FaShoppingBag />,
  "Dépenses Recettes": <FaCalculator />,
  Coachings: <MdTimer />,
  "Heures Enseignement": <FaHourglassHalf />,
  Inventaire: <MdOutlineInventory />,
  "Chat Page Admin": <FaComment />,
  "Message Admin": <FaEnvelope />,
  Paramètres: <IoMdSettings />,
  "Requêtes Admin": <RiQuestionnaireFill />,
};

const sections = {
  2: {
    label: "Utilisateur",
    links: [
      "Tableau de Bord",
      "Dossier d'Inscription",
      "Fiche Adhérent",
      "Calendrier",
      "Messages",
      "Chat",
      "Boutique",
      "Commandes & Règlements",
      "Actualités",
      "Galerie Photos",
      "Lexique",
      "Arbitrage Judo",
      "Judo Score",
      "Techniques Judo",
      "Techniques Quizz",
    ],
  },
  3: {
    label: "Enseignant",
    links: [
      "Listing Adhérents",
      "Appel Présence",
      "Tableau Appel",
      "Passage Ceinture",
      "Grade CN",
      "Événements",
      "Suivi Compétitions",
      "Palmarès",
      "ToDoList",
    ],
  },
  1: {
    label: "Administrateur",
    links: [
      "Dashboard Adhérents",
      "Dashboard Comptabilité",
      "Détails Adhérent",
      "Listing Utilisateurs",
      "Listing Adhérents Admin",
      "Règlements Inscriptions",
      "Dossiers d'Inscription",
      "Achats Boutique",
      "Articles Admin",
      "Dépenses Recettes",
      "Coachings",
      "Heures Enseignement",
      "Inventaire",
      "Chat Page Admin",
      "Message Admin",
      "Paramètres",
      "Requêtes Admin",
    ],
  },
};

const MegaMenu = ({ role_id, settings, onClose }) => {
  // Création d'un tableau ordonné basé sur le role_id pour l'ordre spécifique des sections
  const orderedSections =
    role_id === 1
      ? [sections[2], sections[3], sections[1]] // Toutes les sections pour l'administrateur dans l'ordre 2, 3, 1
      : role_id === 3
      ? [sections[2], sections[3]] // Sections Utilisateur et Enseignant pour les enseignants
      : [sections[2]]; // Seulement la section Utilisateur pour les autres utilisateurs

  const getCardBackgroundColor = (sectionLabel) => {
    if (sectionLabel === "Enseignant") return "bg-white hover:bg-blue-100"; // Bleu pour les enseignants
    if (sectionLabel === "Administrateur") return "bg-white hover:bg-blue-100"; // Vert pour les administrateurs
    return "bg-white hover:bg-blue-100"; // Blanc pour les utilisateurs
  };

  const shouldDisplayLink = (link) => {
    const keyMap = {
      Boutique: "display_boutique",
      Chat: "display_chat",
      Actualités: "display_news",
      "Galerie Photos": "display_galerie",
      Lexique: "display_lexique",
      "Techniques Judo": "display_techniques",
      "Arbitrage Judo": "display_arbitrage",
      "Appel Présence": "display_appels",
      ToDoList: "display_todolist",
      Coachings: "display_coaching",
      "Heures Enseignement": "display_heures_enseignants",
      Inventaire: "display_inventaire",
      "Chat Page Admin": "display_chat_admin",
      "Message Admin": "display_messages_admin",
    };

    const key = keyMap[link];
    return key ? settings[key] : true;
  };

  return (
    <div className="max-w-[1280px] mx-auto bg-base-200 dark:bg-slate-600 font-montserrat overflow-hidden relative">
      <div className="overflow-y-auto max-h-[70vh]">
        {/* Bouton de fermeture */}
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 mr-6 text-white bg-primary hover:bg-primary-dark rounded-full focus:outline-none h-8 w-8"
          style={{ zIndex: 10 }}
        >
          ×
        </button>
        {orderedSections.map((section, idx) => (
          <div key={idx} className="p-6">
            <div className="font-montserrat text-primary dark:text-white shadow-sm text-lg font-semibold mb-4 ">
              {section.label}
            </div>
            <ul className="grid grid-cols-2 md:grid-cols-4 gap-4 ">
              {section.links.filter(shouldDisplayLink).map((link, linkIdx) => (
                <li key={linkIdx} className="text-center ">
                  <NavLink
                    to={`/${link
                      .toLowerCase()
                      .replace(/[\s'ç]/g, "-")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}`}
                    className={`dark:bg-gray-800 block relative overflow-hidden rounded-lg p-3 shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${getCardBackgroundColor(
                      section.label
                    )}`}
                    onClick={onClose}
                  >
                    <div className="flex flex-col items-center justify-center dark:bg-gray-800 ">
                      <div className="text-3xl text-primary my-3">
                        {linkIcon[link]}
                      </div>
                      <span className="block text-sm font-semibold text-gray-800 dark:text-white">
                        {link}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
