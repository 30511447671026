import React, { useState, useEffect } from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import {
  getTechniquesJudo,
  getJudoTechniquesWithoutSelfDefense,
} from "../../../services/techniquesServices";

const TechniquesQuizz = () => {
  useScrollToTop();
  const [techniques, setTechniques] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [errors, setErrors] = useState([]);
  const [grade, setGrade] = useState("Toutes les ceintures");
  const [includeSelfDefense, setIncludeSelfDefense] = useState(false); // État pour inclure ou non Self-Défense

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  // Récupérer userId depuis localStorage
  const getUserIdFromLocalStorage = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return userInfo?.userId;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de userId depuis localStorage:",
        error
      );
      return null;
    }
  };

  const userId = getUserIdFromLocalStorage();

  useEffect(() => {
    const fetchTechniques = async () => {
      const fetchedTechniques = includeSelfDefense
        ? await getTechniquesJudo()
        : await getJudoTechniquesWithoutSelfDefense();
      setTechniques(fetchedTechniques);
    };

    fetchTechniques();
  }, [includeSelfDefense]);

  const generateQuestions = () => {
    if (!techniques.length) return;

    // Filtrer les techniques selon la difficulté (grade)
    const filteredTechniques =
      grade === "Toutes les ceintures"
        ? techniques
        : techniques.filter((tech) => tech.ceinture === grade);

    // Mélanger les techniques et sélectionner 10
    const selectedQuestions = filteredTechniques
      .sort(() => Math.random() - 0.5)
      .slice(0, 10)
      .map((technique) => {
        const sameFamilyTechniques = techniques.filter(
          (t) => t.famille === technique.famille && t.id !== technique.id
        );

        // Obtenir 2 mauvaises réponses
        const wrongAnswers = sameFamilyTechniques
          .sort(() => Math.random() - 0.5)
          .slice(0, 2)
          .map((t) => t.nom);

        // Mélanger les propositions
        const options = [...wrongAnswers, technique.nom].sort(
          () => Math.random() - 0.5
        );

        return {
          image: technique.image1_url,
          correctAnswer: technique.nom,
          options,
          famille: technique.famille,
        };
      });

    setQuestions(selectedQuestions);
  };

  useEffect(() => {
    if (techniques.length) {
      generateQuestions();
    }
  }, [techniques, grade]);

  const handleAnswer = (answer) => {
    if (!questions.length) return;

    const current = questions[currentQuestion];
    if (answer === current.correctAnswer) {
      setScore(score + 1);
    } else {
      setErrors([...errors, { ...current, selected: answer }]);
    }

    if (currentQuestion === questions.length - 1) {
      setShowResults(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null);
    }
  };

  const handleRestart = () => {
    setScore(0);
    setCurrentQuestion(0);
    setShowResults(false);
    setErrors([]);
    generateQuestions();
  };

  const progressPercentage = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-satoshi uppercase">
        Techniques Quizz
      </h2>

      <div className="overflow-x-auto max-w-[1280px] mx-auto w-full">
        <DashboardNavigation role={userRole} />
        {!showResults && (
          <div className="mb-4">
            <label
              htmlFor="grade"
              className="text-lg text-gray-800 font-satoshi"
            >
              Sélectionnez votre grade :
            </label>
            <select
              id="grade"
              className="select select-bordered w-full mt-2 text-gray-800 font-satoshi"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
            >
              <option value="Toutes les ceintures">Toutes les ceintures</option>
              <option value="Jaune">Jaune</option>
              <option value="Orange">Orange</option>
              <option value="Verte">Verte</option>
              <option value="Bleue">Bleue</option>
              <option value="Marron">Marron</option>
              <option value="Noire">Noire</option>
            </select>

            <div className="mt-4 flex items-center gap-4">
              <label htmlFor="selfDefense" className="text-lg text-gray-800">
                Ajouter Self-Défense :
              </label>
              <input
                id="selfDefense"
                type="checkbox"
                className="toggle toggle-primary"
                checked={includeSelfDefense}
                onChange={() => setIncludeSelfDefense(!includeSelfDefense)}
              />
            </div>
          </div>
        )}

        {showResults ? (
          <div className="max-w-[1280px] mx-auto p-6 font-satoshi">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-bold">Résultats</h2>
                <p className="text-lg">Score : {score} / 10</p>
              </div>
              <button
                className="btn bg-primary hover:bg-black mt-4"
                onClick={handleRestart}
              >
                Recommencer
              </button>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-6">
              {questions.map((question, index) => {
                const userAnswer = errors.find(
                  (error) => error.correctAnswer === question.correctAnswer
                )?.selected;

                const isCorrect = !userAnswer;

                return (
                  <div
                    key={index}
                    className={`p-4 border rounded-md ${
                      isCorrect
                        ? "bg-green-200 border-green-500"
                        : "bg-red-200 border-red-500"
                    }`}
                  >
                    <img
                      src={question.image}
                      alt={question.correctAnswer}
                      className="w-full h-48 object-cover mb-2"
                    />
                    <p className="text-center font-bold text-lg">
                      {isCorrect ? (
                        <span className="text-green-800">
                          {question.correctAnswer}
                        </span>
                      ) : (
                        <>
                          <span className="line-through text-red-800">
                            {userAnswer}
                          </span>
                          <br />
                          <span className="text-green-800">
                            {question.correctAnswer}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="max-w-[1280px] mx-auto p-6">
            {questions.length && (
              <>
                <div className="mb-4">
                  <p className="text-lg font-bold text-gray-800">
                    Question {currentQuestion + 1} / {questions.length}
                  </p>
                  <div className="w-full bg-gray-400 h-4 rounded-md overflow-hidden my-2">
                    <div
                      className="bg-primary h-4 rounded-md"
                      style={{ width: `${progressPercentage}%` }}
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center gap-4">
                  <div className="grid grid-cols-1 gap-4 lg:w-1/3 font-satoshi font-semibold">
                    {questions[currentQuestion].options.map((option, index) => (
                      <button
                        key={index}
                        style={{
                          fontSize: "18px",
                          padding: "12px",
                        }}
                        className="btn bg-primary hover:bg-black w-full text-gray-800"
                        onClick={() => handleAnswer(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>

                  <div className="flex justify-center lg:w-2/3">
                    <img
                      src={questions[currentQuestion].image}
                      alt="Technique"
                      className="w-[450px] h-[500px] object-cover bg-gray-200"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TechniquesQuizz;
