import React, { useState, useEffect } from "react";
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaInfoCircle,
  FaCalendarCheck,
  FaUser,
  FaTimesCircle,
  FaHeart,
  FaRegHeart,
} from "react-icons/fa"; // Import des icônes
import Button from "./../../../components/Button/Button";
import {
  getAdherentsCountByEvent,
  getAdherentsByEvent,
  registerAdherent,
  unregisterAdherent,
} from "./../../../services/eventServices";
import {
  getAdherentsByUser,
  getAllAdherentsList,
} from "./../../../services/adherentServices";
import { sendNotification } from "./../../../services/notificationServices";
import { getAdminsAndInstructorsIds } from "./../../../services/userServices";
import {
  addUserEvent,
  deleteUserEvent,
  getUsersEvents,
} from "./../../../services/usersEventsServices"; // Import des fonctions de gestion des favoris
import EventDetailsModalEnseignants from "./../../PagesEnseignants/EventsPage/EventDetailsModalEnseignants";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import { ToastContainer, toast } from "react-toastify";

const EventCardUser = ({ event, userId }) => {
  const [adherentsCount, setAdherentsCount] = useState(0);
  const [adherents, setAdherents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userAdherents, setUserAdherents] = useState([]);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [unregisterModalOpen, setUnregisterModalOpen] = useState(false);
  const [selectedAdherent, setSelectedAdherent] = useState("");
  const [isFavorite, setIsFavorite] = useState(false); // État pour les favoris
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const isAdminOrInstructor = [1, 3].includes(user?.role_id);
  const isEventPast = new Date(event.end || event.start) < new Date();
  const eventBgClass = isEventPast ? "bg-slate-300" : "bg-slate-100";
  const [selectedImage, setSelectedImage] = useState(null);

  // Fonction pour ouvrir la modale avec l'image sélectionnée
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setSelectedImage(null);
  };

  const loadData = async () => {
    if (event.is_active) {
      try {
        const [adherentsCountData, eventAdherents, allAdherents] =
          await Promise.all([
            getAdherentsCountByEvent(event.id),
            getAdherentsByEvent(event.id),
            isAdminOrInstructor
              ? getAllAdherentsList()
              : getAdherentsByUser(user.id),
          ]);
        setAdherentsCount(adherentsCountData);
        setAdherents(eventAdherents);
        setUserAdherents(allAdherents);

        // Vérifiez si l'événement est déjà en favori pour l'utilisateur
        const favorites = await getUsersEvents(userId);
        setIsFavorite(favorites.some((fav) => fav.event_id === event.id));
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [event, user.id, isAdminOrInstructor, userId]);

  if (!event.is_active) return null; // Ne pas afficher les événements inactifs

  const isUserRegistered = adherents.some((adherent) =>
    userAdherents.some((userAdherent) => userAdherent.id === adherent.id)
  );

  const handleUnregister = () => {
    setUnregisterModalOpen(true);
  };

  const confirmUnregister = async () => {
    if (!selectedAdherent) {
      toast.error("Veuillez sélectionner un adhérent.");
      return;
    }
    try {
      await unregisterAdherent(selectedAdherent, event.id);
      const adh = adherents.find(
        (adh) => adh.id === parseInt(selectedAdherent)
      );
      const message = `Désinscription réussie de ${adh.nom} ${
        adh.prenom
      } de l'événement: ${event.titre} du ${new Date(
        event.start
      ).toLocaleDateString("fr-FR")}`;

      // Supprimer l'adhérent de la liste des adhérents inscrits
      setAdherents((prev) =>
        prev.filter((adh) => adh.id !== parseInt(selectedAdherent))
      );

      const recipients = await getAdminsAndInstructorsIds();
      recipients.forEach(async (recipient) => {
        try {
          await sendNotification(message, recipient.id, user.token);
        } catch (error) {
          console.error(
            "Erreur lors de l'envoi de la notification pour l'ID:",
            recipient.id,
            error
          );
        }
      });

      toast.success("Désinscription réussie!");
      setAdherentsCount((prev) => prev - 1);
      setUnregisterModalOpen(false);
      loadData(); // Rafraîchir les données après désinscription
    } catch (error) {
      toast.error("Erreur lors de la désinscription.");
      console.error("Désinscription error:", error);
    }
  };

  // Ajout d'une fonction pour filtrer les adhérents affichables
  const filterAdherentsForUnregistration = () => {
    return isAdminOrInstructor
      ? adherents
      : adherents.filter((adh) =>
          userAdherents.map((uAdh) => uAdh.id).includes(adh.id)
        );
  };

  // Logique pour ouvrir la modale de désinscription avec la liste filtrée
  const openUnregisterModal = () => {
    if (
      isAdminOrInstructor ||
      userAdherents.some((adherent) =>
        adherents.map((a) => a.id).includes(adherent.id)
      )
    ) {
      handleUnregister();
    } else {
      toast.error(
        "Vous n'avez pas les permissions pour désinscrire des adhérents que vous ne gérez pas."
      );
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const confirmRegistration = async () => {
    if (!selectedAdherent) {
      toast.error("Aucun adhérent sélectionné.");
      return;
    }

    const adherentExists = adherents.some(
      (adh) => adh.id === parseInt(selectedAdherent)
    );
    if (adherentExists) {
      toast.warning("Cet adhérent est déjà inscrit à cet événement.");
      return;
    }

    try {
      await registerAdherent(event.id, selectedAdherent);
      const adh = userAdherents.find(
        (adh) => adh.id === parseInt(selectedAdherent)
      );

      setAdherents((prev) => [...prev, adh]);

      const message = `Inscription réussie de ${adh.nom} ${
        adh.prenom
      } sur l'événement: ${event.titre} du ${new Date(
        event.start
      ).toLocaleDateString("fr-FR")}`;

      const recipients = await getAdminsAndInstructorsIds();
      recipients.forEach(async (recipient) => {
        try {
          await sendNotification(message, recipient.id, user.token);
        } catch (error) {
          console.error(
            "Erreur lors de l'envoi de la notification pour l'ID:",
            recipient.id,
            error
          );
        }
      });

      toast.success("Inscription réussie!");
      setAdherentsCount((prev) => prev + 1);
      setRegisterModalOpen(false);
      loadData(); // Rafraîchir les données après inscription
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      toast.error("Erreur lors de l'inscription.");
    }
  };

  const handleRegister = () => {
    setRegisterModalOpen(true);
  };

  const handleInfo = () => {
    setModalOpen(true); // Ouvrir la modale
  };

  // Fonction pour gérer l'ajout/suppression de favoris
  const handleFavoriteToggle = async () => {
    if (isFavorite) {
      await deleteUserEvent(userId, event.id);
    } else {
      await addUserEvent(userId, event.id);
    }
    setIsFavorite(!isFavorite);
  };

  const refreshList = async () => {
    await loadData();
  };

  // Fonction mise à jour pour obtenir le tag de statut
  const getStatusTag = () => {
    const userRegisteredAdherents = adherents.filter((adherent) =>
      userAdherents.some(
        (userAdherent) =>
          userAdherent.id === adherent.id &&
          adherent.AdherentEvent?.is_validate !== undefined
      )
    );

    if (userRegisteredAdherents.length > 0) {
      const registeredAdherent = userRegisteredAdherents[0];
      return registeredAdherent.AdherentEvent.is_validate ? (
        <div className="absolute top-0 left-0 bg-green-500 text-white px-4 py-2 text-sm font-bold">
          INSCRIT
        </div>
      ) : (
        <div className="absolute top-0 left-0 bg-yellow-500 text-black px-4 py-2 text-sm font-bold">
          PRÉ-INSCRIT
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`${eventBgClass} border-slate-300 dark:border-slate-600 border-2 dark:bg-slate-500 shadow-md rounded-lg overflow-hidden flex flex-col min-h-[400px] font-satoshi relative`}
    >
      {getStatusTag()}
      <img
        src={event.photo_url}
        alt={event.titre}
        className="w-full h-64 object-cover flex-shrink-0"
        onClick={() => openModal(event.photo_url || "/img/no-image.jpg")} // Ouvre la modale avec l'image par défaut si aucune image
      />
      <div className="flex flex-col flex-grow p-4 justify-between">
        <div>
          <h3 className="text-normal font-bold text-gray-600 dark:text-slate-100">
            {event.titre}
          </h3>
          <p className="text-sm text-gray-600 dark:text-slate-300">
            {event.description}
          </p>
          <div className="mt-2">
            <div className="flex items-center text-gray-700 text-sm dark:text-slate-300">
              <FaCalendarAlt className="mr-2" />
              {formatDate(event.start)} -{" "}
              {event.end ? formatDate(event.end) : ""}
            </div>
            <div className="flex items-center text-gray-700 text-sm mt-1 dark:text-slate-300">
              <FaMapMarkerAlt className="mr-2" />
              {event.lieu_event || "Lieu à préciser"}
            </div>
            <div className="flex items-center text-gray-700 text-sm mt-1 dark:text-slate-300">
              <FaUser className="mr-2" /> Inscrits : {adherentsCount}
            </div>
            {event.agenda_url && (
              <a
                href={event.agenda_url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-black hover:text-primary dark:text-slate-300 dark:hover:text-primary text-sm mt-1"
              >
                <FaInfoCircle className="mr-2" />
                Ajouter à votre agenda
              </a>
            )}
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            text="S'inscrire"
            onClick={handleRegister}
            icon={FaCalendarCheck}
            className="w-sm mr-2 gap-2 text-sm"
            hoverLabel="Cliquez pour vous inscrire"
          />
          {isUserRegistered && (
            <Button
              text="Désinscrire"
              onClick={handleUnregister}
              icon={FaTimesCircle}
              className="w-sm mr-2 gap-2 text-sm"
              hoverLabel="Cliquez pour vous désinscrire"
            />
          )}
          <Button
            text=""
            onClick={handleInfo}
            icon={FaInfoCircle}
            className="w-sm gap-2 text-sm mr-2"
            hoverLabel="Cliquez pour plus d'infos"
          />
          <Button
            text=""
            onClick={handleFavoriteToggle}
            icon={isFavorite ? FaHeart : FaRegHeart}
            className="w-sm gap-2 text-sm"
            hoverLabel="Ajouter/Retirer des favoris"
          />
        </div>
      </div>
      {/* Modale pour inscription */}
      {registerModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="modal-box bg-white dark:bg-slate-600 p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Inscription
            </h2>
            <h3 className="font-semibold mb-2 text-center">{event.titre}</h3>
            <h3 className="italic mb-2 text-center">
              {formatDate(event.start)} {event.end ? formatDate(event.end) : ""}
            </h3>
            <h2 className="mb-2 text-left">Choisissez un adhérent</h2>
            <select
              className="input input-bordered w-full mb-5"
              value={selectedAdherent}
              onChange={(e) => setSelectedAdherent(e.target.value)}
            >
              <option value="">Sélectionner un adhérent</option>{" "}
              {/* Ajoutez cette ligne pour avoir une option par défaut */}
              {userAdherents.map((adherent) => (
                <option key={adherent.id} value={adherent.id}>
                  {adherent.nom} {adherent.prenom}
                </option>
              ))}
            </select>
            <div className="flex justify-center">
              <Button
                text="Confirmer l'inscription"
                onClick={confirmRegistration}
                className="w-sm mr-4"
              />
              <Button
                text="Fermer"
                onClick={() => setRegisterModalOpen(false)}
                className="w-sm"
              />
            </div>
          </div>
        </div>
      )}
      {unregisterModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="modal-box bg-white dark:bg-slate-600 p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Confirmer la désinscription
            </h2>
            <select
              className="input input-bordered w-full mb-4"
              value={selectedAdherent}
              onChange={(e) => setSelectedAdherent(e.target.value)}
            >
              <option value="">Sélectionner l'adhérent</option>
              {filterAdherentsForUnregistration().map((adh) => (
                <option key={adh.id} value={adh.id}>
                  {adh.nom} {adh.prenom}
                </option>
              ))}
            </select>
            <div className="flex justify-center">
              <Button
                text="Confirmer"
                onClick={confirmUnregister}
                className="w-sm mr-4"
              />
              <Button
                text="Annuler"
                onClick={() => setUnregisterModalOpen(false)}
                className="w-sm"
              />
            </div>
          </div>
        </div>
      )}
      {/* Modale pour plus de détails */}
      <EventDetailsModalEnseignants
        event={event}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        adherents={adherents}
        refreshList={refreshList}
      />
      {/* Modale pour afficher l'image en grand */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Agrandissement"
              className="max-w-full max-h-[80vh] rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={closeModal}
              aria-label="Fermer"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default EventCardUser;
